import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import NewNotify from "./NewNotify";

const DigitalDetox = ({setNotification}: {
    setNotification: (message: string, type: 'success' | 'error') => void
}) => {
    const [message, setMessage] = useState<string | null>(null)
    const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);

    useEffect(() => {
        document.title = "디지털디톡스"
    }, [document.title]);

    return (
        <div style={{ marginLeft: window.innerWidth > 700 ? '1%': '3%', marginBottom: "2%", maxWidth: window.innerWidth > 700 ? '40%' : '100%', // Responsive width
            marginRight: window.innerWidth > 700 ? '10%' : '5%' }}>
            <div>
            <h2>디지털 디톡스를 위한 몇 가지 제안들</h2>
            {/* <div style={{color: '#2eb872'}}>
            이 문제와 관련해서 각국에서 정책적인 대응(소셜미디어 및 개인정보 보호, 확률형 아이템 규제 등)이 진행 중입니다.
            <br/>
            <br/>
            해당 내용은 
            <Link to='/apps/인스타그램' style={{ color: "#7c73e6", textDecoration: "none" }}> 인스타그램, </Link>
            <Link to='/apps/메이플스토리' style={{ color: "#7c73e6", textDecoration: "none" }}>메이플스토리 
            </Link> 페이지 참고자료에서 확인할 수 있습니다.
            <br/>
            <br/>
                 '디지털 디톡스' 페이지는 각국의 정책적인 대응이 아직 진행중이기에 개인적인 해결책을 고민해보는 페이지입니다.
                 </div> */}
                 <h3>개인 및 가족</h3>
                <h4>1. 가디언 - 두뇌 되찾기(Reclaim your brain) 시리즈</h4>
                언론사 가디언의 2024년 새해 시리즈 <a href="https://www.theguardian.com/lifeandstyle/2023/dec/14/sign-up-to-reclaim-your-brain-our-free-email-to-help-you-scroll-less-and-live-more?utm_term=65a51f88489a6d98cda85b91548e9fa4&utm_campaign=ReclaimYourBrain&utm_source=esp&utm_medium=Email&CMP=reclaimyourbrain_email"
                    style={{ color: "#7c73e6", textDecoration: "none" }}>'두뇌 되찾기(Reclaim your brain)'</a>에는 몇 가지 조언이 소개된다.
                <br />
                이 시리즈에서 소개된 조언에 추가적인 아이디어(+표시)를 더해보았다.
                <br />
                <br />
                1. 휴대폰이 아닌 알람 시계 이용하기 / 휴대폰 침실에서 충전하지 않기
                <br />(원룸이라면 침대에서 떨어진 곳에서 휴대폰 충전하기)
                <br />
                <br />
                2. 휴대폰에 방지턱 만들기
                <br />(휴대폰에 고무줄이나 머리띠 채우기)
                <br />
                <br />
                + 복잡한 비밀번호로 방지턱 만들기(아이폰)
                <br />(아이폰의 경우, 스크린 타임 비밀번호를 기억하지 못하게 무작위로 설정해도 애플 계정 로그인을 통해 스크린 타임 비밀번호를 재설정할 수 있다.
                <br /> 애플 계정의 비밀번호를 아주 복잡하게 만들면 스크린 타임 해제를 방지할 수 있다.)
                <br />
                + 잠금 어플리케이션으로 방지턱 만들기(안드로이드)
                <br />(안드로이드의 경우, 화면 잠금 어플리케이션을 통해 사용할 어플리케이션을 정해서 이용할 수 있다.)
                <br />
                <Link to='/os' style={{ color: "#7c73e6", textDecoration: "none" }}>+ 트랩위키 '운영체제별 대처법' 페이지 참조</Link>
                <br />
                <br />
                3. 휴대폰 알림 끄기
                <br />
                <Link to='/principles/알림' style={{ color: "#7c73e6", textDecoration: "none" }}>+ 트랩위키 '알림'의 참고자료에 안드로이드/iOS 알림 관련 링크가 있다.</Link>
                <br />
                <br />
                4. 유혹 앱(Temptation App)은 지우고, 도구 앱(Tool App)만 남기기
                <br />
                휴대폰에{" "}
                <Link to='/apps/인스타그램' style={{ color: "#7c73e6", textDecoration: "none" }}>소셜미디어,{" "}</Link>
                <Link to='/apps/유튜브' style={{ color: "#7c73e6", textDecoration: "none" }}>유튜브{" "}</Link>
                등 유혹 앱은 지우고, 도구 앱(지도, 은행 앱 등)만 남긴다.
                <br />
                <br />
                5(+). 1번에서 알람 시계로 스마트폰을 대체했던 것처럼, 다른 영역에서도 스마트폰의 대체품을 찾을 수 있다.
                <br/>
                예를 들면, 닌텐도 등의 휴대용 게임기로 광고 및 <a href="/principles/확률형 아이템" style={{ color: "#7c73e6", textDecoration: "none" }}>
         확률형 아이템,
          </a>{" "}<a href="/principles/출석 보상 시스템" style={{ color: "#7c73e6", textDecoration: "none" }}>
         출석 보상 시스템
          </a>{" "} 없이 더 괜찮은 게임을 즐길 수 있다.
                <br/>
                전자사전으로 스마트폰 알림 등의 방해 없이 사전을 이용할 수 있다.
            </div>
            <div>
            <h4>2. 집에서 스마트폰을 놓아둘 곳 마련하기</h4>
            <iframe width="98%" height="315" src="https://www.youtube.com/embed/aZcbJl1IOWM?si=cLKO6eNTYSdVEA--&amp;start=920" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            <br /><br />
            위 영상의 15분 20초의 예시처럼 집에서 활동하는 동선으로부터 떨어진 곳에 스마트폰을 놓아두면 사용 시간을 줄일 수 있다.
            <br />
            1-1에서 언급한 스마트폰을 침실 바깥에 두는 것과 연결되는 사항이다.
            </div>
            <h4>3. 휴대폰 없이 산책 및 가까운 거리 외출</h4>
            <div>
                조너선 하이트는 '불안 세대' 315쪽에서 휴대폰 없이 '경외감에 사로잡힌 산책(Awe Walk)'을 할 것을 권하고 있다.
                <br/>
                '불안 세대'에서 제시된 대로 공원이나 자연 풍광을 산책하거나, 짧게 외출할 때 휴대폰 없이 나가보는 경험을 하면,
                휴대폰에 대한 의존을 낮출 수 있다.
            </div>
            <h4>4. 영화관과 같은 대안적 장소들 찾아보기</h4>

            <div>
                            <div style={{
                    // position: 'absolute', top: document.documentElement.clientWidth <= 1300 ? '105%' : '55%',
                   color: '#7c73e6'
                }}><a href="http://www.cine21.com/news/view/?mag_id=104793" style={{ color: "#7c73e6" }}>
                    [인터뷰] 영화비평과 ‘필름적 현실’의 특권화, 하스미 시게히코 ②(링크)</a></div>
                <br/>  
                <div style={{
                  color: '#7c73e6'
                }}>
                    "다만 주변 세계에 어떤 시선을 보내야 하는지에 대해서는 각자 나름대로 민감해야 한다고 생각한다. 현실 사회라는 것의 실태와 주변에 유통되고 있는 그 전자적(電子的)인 표상작용과의 관계를 식별하는 것이 매우 곤란해지고 있다. 때문에 자신이 잘못된 판단을 내렸다고 자각했을 경우, 그것을 수정하는 능력만큼은 몸에 익혔으면 한다. 지금은 AI를 비롯한 주변의 환경으로 인해 올바름에 대한 판단이 어려운 시대다. 이럴 때 필요한 건 전자적인 환경 그 자체로부터 거리를 두는 것이다. 그보다는 극장에 가서 스크린에 투영되는 ‘영화의 필름적 현실’과 무심하게 마주하길 바란다."
                    <br/>  <br/>
                    "그러나 영화란 고독한 동시에 또 집단적인 체험이기도 하다. 때문에 PC나 휴대전화의 미소한 화면과는 다른, 영화관의 큰 스크린을 앞에 두고 많은 관객과 동시에 영화의 ‘필름적인 현실’의 추이를 공유해주었으면 했다. 그것은 휴대 가능한 전자적인 기기의 작은 화면으로 영화를 보는 것과는 전혀 이질적인 체험이다. 때로는 스마트폰으로부터 해방되어 영화관을 발견해주길 바란다."
                </div>
                <br/>
                <div>위의 말에서 어떻게 스마트폰에서 떨어져서 다른 현실을 경험할지 힌트를 얻을 수 있다. 영화관처럼 휴대폰을 끄는 장소에 방문한다면 휴대폰으로부터 잠시나마 멀어져서 다른 현실을 경험할 수 있다.</div>
            </div>
                        <div>
            <h4>5. 데스크탑 및 랩탑(노트북) 작동 시간 미리 정해놓기</h4>
            <img src={require('../coldturkey.png')} alt="" width={'98%'} height={315}/>
            <div>콜드 터키 프로그램의 유료 기능 중에는 시간을 미리 정해놓고 컴퓨터와 랩탑을 잠그는 기능이 있다(윈도우와 맥 지원)</div>
            </div>
            <h3>학교</h3>
            <h4>1. 학교 내 휴대폰 금지</h4>
            <div>
                최근 유럽 및 미국에서{" "}
                <Link to='https://www.khan.co.kr/article/202408281339001' style={{ color: "#7c73e6", textDecoration: "none" }}>
                교내 스마트폰 사용을 금지하는 방안(링크는 프랑스 사례 기사)</Link>이 도입되고 있다. 
                <br/>
                조너선 하이트는 '불안 세대'에서 학교 내 스마트폰 금지와 '자유 놀이'의 확산이 이루어져야 한다고 주장한다.
                <br/>
                전면 금지가 생각보다 쉽게 이루어지는 이유는 혼자 행동할 때보다 다 함께 행동할 때 개인이 받는 압박감이 적어지기 때문이다.
                <br/>
                나 혼자 소셜미디어를 안하고 있을 때 교내에서 청소년이 받는 놀림과 압박감은 상당하다고 한다. 
                학교에서 스마트폰을 금지함으로써 학생들은 다 함께 압박감에서 벗어나게 된다.
            </div>
            <h4>2. 자유 놀이 공간과 시간의 확대</h4>
             <div>조너선 하이트는 '불안 세대'에서 교내 스마트폰 금지와 함께 자유롭게 놀 수 있는 시간과 공간이 확대되어야 한다고 주장한다.
             </div>
        </div>
    )
}

export default DigitalDetox;