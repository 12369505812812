import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { FIND_REF } from '../queries'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/material/Button';
import Loading from './Loading'
import EditRef from './EditRef'
import Time from './Time';

const RefInfo = ({ setNotification, token }: {
    setNotification: (message: string, type: 'success' | 'error') => void, token: string | null
}) => {
    const navigate = useNavigate()
    const [openEditor, setOpenEditor] = useState(false);// onClose, , onClose: () => void

    let { id } = useParams()
    id ??= ""
    const { data, loading, error } = useQuery(FIND_REF, {
        variables: { id: Number(id) },
        skip: !id
    })

    useEffect(() => {
        if (data?.findReference?.title) {
            document.title = data.findReference.title
        }
    }, [data?.findReference?.title]);

    if (loading) return <Loading />
    if (error) return <p>ERROR: {error.message}</p>;
    if (!data) return <p>Not found</p>;
    
    return (
        data.findReference &&
        <div style={{ marginLeft: window.innerWidth > 700 ? '1%': '3%', marginBottom: "2%", maxWidth: window.innerWidth > 700 ? '40%' : '100%', // Responsive width
            marginRight: window.innerWidth > 700 ? '10%' : '5%' }}>
            {openEditor ? null :
                <div>
                    {/* <div style={{display: 'flex', justifyContent: 'space-between'}}> */}
                    <h2 style={{}}>{`${data.findReference.title} ${data.findReference.author}`}
                    </h2>
                    <hr />
                    <div style={{ whiteSpace: 'break-spaces', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                        {data.findReference.description}
                    </div>
                    <hr />
                    <a href={data.findReference.link} style={{ color: "#7c73e6", textDecoration: "none" }}>{data.findReference.linkTitle}{`(${data.findReference.link?.slice(0, 15)}···)`}</a>
                    <br />
                    <br />
                    
                    <div style={{ display: 'flex', justifyContent: document.documentElement.clientWidth <= 1300 ? 'flex-end' : 'flex-start' }}>
                        {token ? <Button variant="contained" onClick={() => setOpenEditor(!openEditor)}
                        >수정</Button> : null}
                        <Button variant="contained" color="error" onClick={() => navigate('/references')}
                        >닫기</Button>
                    </div>
                    <Time createdAt={data.findReference.createdAt} updatedAt={data.findReference.updatedAt}/>
                </div>}
            {openEditor ? <EditRef setNotification={setNotification} reference={data.findReference} setOpenEditor={setOpenEditor} /> : null}
        </div>
    )
}

export default RefInfo;